.our-work-container {
    line-height: 1.5rem;
    background-color: #ffffff;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: calc(100vw / 6);
    padding-right: calc(100vw / 6);
}

.work-gallery {
    display: flex;
    gap: 20px;
    padding: 20px 0;
    white-space: nowrap;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 90vw;
    margin-top: 20px;
    height: 400px;
    overflow: hidden; /* Ensures images don’t break layout */
}

.work-gallery img {
    width: calc(100vw / 3);
    height: 350px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease, opacity 0.5s ease;
    flex-shrink: 0;
    position: absolute;
    opacity: 0.5;
    transform: scale(0.8);
}

.work-gallery img.active {
    opacity: 1;
    transform: scale(1);
    z-index: 2;
}

.work-gallery img.prev {
    opacity: 0.7;
    transform: translateX(-50%) scale(0.9);
    z-index: 1;
}

.work-gallery img.next {
    opacity: 0.7;
    transform: translateX(50%) scale(0.9);
    z-index: 1;
}

.get-website-button {
    display: inline-block;
    padding: 12px 20px;
    background: linear-gradient(45deg, #ff6b00, #ff9800); /* Vibrant Gradient */
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 50px; /* Rounded shape for a premium look */
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: none;
    box-shadow: 0px 4px 10px rgba(255, 105, 0, 0.3);
    position: relative;
    overflow: hidden;
  }
  
  .get-website-button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300%;
    height: 300%;
    background: rgba(255, 255, 255, 0.2);
    transition: all 0.6s ease-in-out;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
  }
  
  .get-website-button:hover::after {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
  
  .get-website-button:hover {
    background: linear-gradient(45deg, #ff9800, #ff6b00);
    transform: scale(1.08);
    box-shadow: 0px 6px 15px rgba(255, 105, 0, 0.5);
  }
  

/* Tablet (768px) */
@media (max-width: 768px) {
    .work-gallery {
        padding: 10px 0;
        width: 95vw;
        height: 300px;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }

    .work-gallery img {
        width: 80%;
        height: 250px;
    }

    .work-gallery img.active {
        transform: scale(1);
    }

    .work-gallery img.prev,
    .work-gallery img.next {
        transform: translateX(0) scale(0.9);
    }
}

/* Mobile (412px) */
@media (max-width: 412px) {
    .work-gallery {
        width: 95vw;
        height: 250px;
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
    }

    .work-gallery img {
        width: 90%;
        height: 200px;
    }

    .work-gallery img.active {
        transform: scale(1);
    }

    .work-gallery img.prev,
    .work-gallery img.next {
        transform: translateX(0) scale(0.85);
    }
}
@media (max-width: 480px) {
    .work-gallery {
        width: 95vw;
        height: 280px; /* Adjust height for better fit */
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
    }

    .work-gallery img {
        width: 85%; /* Maintain proportion */
        height: 220px;
    }

    .work-gallery img.active {
        transform: scale(1);
    }

    .work-gallery img.prev,
    .work-gallery img.next {
        transform: translateX(0) scale(0.88);
    }

    .get-website-button {
        font-size: 16px; /* Adjust button size */
        padding: 10px 18px;
    }
}
