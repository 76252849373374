body {
  padding: 0;
  width: 100%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  background-color: white;
  overflow-x: hidden;
  cursor: pointer;
}
section {
  margin: 0;
  padding: 0;
}
section > * {
  margin: 0;
  padding: 0;
  line-height: 1;
}
.home-component,
.about-component,
.services-component,
.portfolio-component,
.testimonials-component,
.faq-component,
.contact-component,
.footer {
  margin: 0;
  padding: 0;
}
section {
  padding: 2rem;
  min-height: max-content;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}
.logo {
  margin: 0 3.5rem;
}
img {
  height: 2.5rem;
}
.nav-links {
  list-style: none;
  display: flex;
  margin-right: 1rem;
}
.nav-links li {
  margin: 0 20px;
}
.nav-links li a {
  text-decoration: none;
  color: #0A7273;
  font-size: 18px;
}
.nav-links li a:hover {
  color: #FDA521;
}
.button-link {
  padding: 1rem 1rem;
  background-color: #FDA521;
  color: white !important;
  border-radius: 1rem;
  text-decoration: none;
  cursor: pointer;
  font-size: 1em;
}
.button-link:hover {
  background-color: #0a7273;
}
.hamburger {
  display: none;
  font-size: 28px;
  color: #0A7273;
  padding: 10px;
  cursor: pointer;
  left: 10px;
}
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
}
.overlay.open {
  display: block;
}

.nav-links .button-link {
  background-color: FDA521;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.nav-links .button-link:hover {
  background-color: #0a7273;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    width: 60%;
    padding: 1rem;
    display: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  .nav-links.open {
    display: block;
    animation: slideIn 0.3s ease-out;
  }
  .nav-links li {
    margin: 10px 40px;
  }
  .hamburger {
    display: block;
    margin-right: 1rem;
    z-index: 1001;
    font-size: 28px;
    padding: 12px;
  }
  .logo {
    margin: 0 1rem;
  }
  .nav-links li a {
    font-size: 16px;
  }
  .button-link {
    font-size: 1rem;
    padding: 0.9rem 1.2rem;
    text-align: center;
    margin: 10px auto;
    display: block;
    width: 80%;
  }

  .nav-links {
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    width: 60%;
    padding: 1rem;
    display: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }
  .nav-links.open {
    display: block;
  }
  .nav-links li {
    margin: 10px 40px;
  }
  .hamburger {
    display: block;
    margin-right: 1rem;
    z-index: 1001;
  }
  .logo {
    margin: 0 1rem;
  }
  .nav-links li a {
    font-size: 16px;
  }
}
@media (max-width: 360px) {
  body {
    font-size: 14px;
  }
  .nav-links li a {
    font-size: 14px;
  }
  .button-link {
    font-size: 0.9em;
    padding: 0.8rem 1rem;
  }
  .hamburger {
    font-size: 24px;
    padding: 8px;
  }
  .logo {
    margin: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-size: 16px;
  }
  .navbar {
    padding: 0.8rem;
  }
  img {
    height: 2rem;
  }
  .nav-links li {
    margin: 10px 15px;
  }

  body {
    font-size: 14px;
  }
  .nav-links li a {
    font-size: 14px;
  }
  .button-link {
    font-size: 0.8rem; /* Reduced font size */
    padding: 0.6rem 0.8rem; /* Reduced padding */
    text-align: center;
    margin: 8px auto; /* Slightly smaller margin */
    width: 85%; /* Reduced width */
}


  .hamburger {
    font-size: 24px;
    padding: 10px;
  }
  .logo {
    margin: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-size: 16px;
  }
  .navbar {
    padding: 0.8rem;
  }
  img {
    height: 2rem;
  }
  .nav-links li {
    margin: 10px 15px;
  }
}

img {
  user-select: none; /* Prevents text/image selection */
  pointer-events: none; /* Disables clicking */
}
