.seo-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
    padding: 15px;
    box-sizing: border-box;
}

.seo-popup-container {
    display: flex;
    flex-direction: row;
    background: white;
    width: 700px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
    transform: scale(0.95);
    animation: popupFadeIn 0.3s ease-out forwards;
    position: relative;
}

@keyframes popupFadeIn {
    from {
        transform: scale(0.95);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.seo-popup-left {
    background: #007070;
    color: white;
    padding: 50px;
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.seo-popup-left p {
    font-size: 40px;
    font-weight: bold;
    margin: 0;
    letter-spacing: 1px;
}

.seo-popup-right {
    padding: 45px;
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff5eb;
}

.seo-popup-title {
    width: 90%;
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 25px;
    color: #222;
    text-align: center;
    font-weight: 600;
}

.seo-popup-input {
    width: 90%;
    padding: 14px;
    font-size: 17px;
    border: 2px solid #007070;
    border-radius: 8px;
    margin-bottom: 20px;
    outline: none;
    transition: border 0.3s ease;
}

.seo-popup-input:focus {
    border-color: #ff6f00;
}

.seo-popup-button {
    width: 100%;
    background: linear-gradient(135deg, #ff6f00, #ff8800);
    color: white;
    border: none;
    padding: 14px;
    font-size: 19px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s;
    box-shadow: 0 4px 10px rgba(255, 111, 0, 0.3);
}

.seo-popup-button:hover {
    background: linear-gradient(135deg, #e65c00, #ff7700);
    transform: translateY(-2px);
    box-shadow: 0 6px 14px rgba(255, 111, 0, 0.4);
}

.seo-popup-button:disabled {
    cursor: not-allowed;
}

.seo-popup-close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    background: white;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    z-index: 1100;
}

.seo-popup-close-icon:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.25);
    transform: scale(1.1);
}

.seo-popup-close-icon::before, 
.seo-popup-close-icon::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background: #333;
    transition: background 0.3s ease;
}

.seo-popup-close-icon::before {
    transform: rotate(45deg);
}

.seo-popup-close-icon::after {
    transform: rotate(-45deg);
}

.seo-popup-close-icon:hover::before,
.seo-popup-close-icon:hover::after {
    background: #ff6f00;
}


@media (max-width: 768px) {
    .seo-popup-close-icon {
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 480px) {
    .seo-popup-close-icon {
        top: 5px;
        right: 5px;
        width: 26px;
        height: 26px;
    }
}

@media (max-width: 412px) {
    .seo-popup-close-icon {
        top: 5px;
        right: 5px;
        width: 26px;
        height: 26px;
    }
}


/* Responsive Design */
@media (max-width: 768px) {
    .seo-popup-container {
        flex-direction: column;
        width: 90%;
    }

    .seo-popup-left {
        padding: 30px;
    }

    .seo-popup-left p {
        font-size: 30px;
    }

    .seo-popup-right {
        padding: 30px;
    }
}

@media (max-width: 480px) {
    .seo-popup-container {
        width: 95%;
    }

    .seo-popup-left {
        padding: 20px;
    }

    .seo-popup-left p {
        font-size: 24px;
    }

    .seo-popup-right {
        padding: 20px;
    }

    .seo-popup-title {
        font-size: 18px;
    }

    .seo-popup-input {
        font-size: 15px;
        padding: 12px;
    }

    .seo-popup-button {
        font-size: 17px;
        padding: 12px;
    }
}

@media (max-width: 412px) {
    .seo-popup-left p {
        font-size: 22px;
    }

    .seo-popup-title {
        font-size: 18px !important;
    }

    .seo-popup-input {
        font-size: 14px;
        padding: 10px;
    }

    .seo-popup-button {
        font-size: 16px;
        padding: 10px;
    }
}
