.terms-container {
    width: 80%;
    margin: 70px auto 0 auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
}

.terms-container h1 {
    text-align: center;
    margin-top: 40px;
    font-size: 35px;
    font-weight: bold;
    color: #2980b9;
}

.terms-container h2 {
    color: #2980b9;
    margin-top: 30px;
    font-size: 20px;
    text-align: left ;
    margin-bottom: 10px;
}

.terms-container h3 {
    color: #34495e;
    font-size: 18px;
    text-align: left;
}

.terms-container p {
    font-size: 14px;
    line-height: 1.6;
    color: #333333;
    margin-bottom: 10px;
}

.terms-container ul {
    margin-left: 0px;
    list-style-type: disc;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 35px;
}

.terms-container ul li {
    margin-bottom: 6px;
}

.terms-container .last-updated {
    font-size: 14px;
    color: #7f8c8d;
    margin-top: 45px;
    text-align: center;
}

@media (max-width: 768px) {
    .terms-container {
        margin-top: 100px;
        padding: 20px;
        width: 80%;
    }

    .terms-container h1 {
        font-size: 35px;
        margin-top: 20px;
    }

    .terms-container h2 {
        font-size: 25px;
        margin-top: 10px;
        margin-bottom: 8px;
    }

    .terms-container h3 {
        font-size: 22px;
    }

    .terms-container p {
        font-size: 20px;
        margin-bottom: 12px;
    }
}

@media (max-width: 360px), (max-width: 412px) {
    .terms-container {
        padding: 10px;
        margin: 15px auto;
    }

    .terms-container h1 {
        font-size: 30px;
        margin-top: 60px;
    }

    .terms-container h2 {
        font-size: 20px !important;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .terms-container h3 {
        font-size: 14px ;
    }

    .terms-container p {
        font-size: 12px ;
    }
}
@media (max-width: 480px) {
    .terms-container {
        padding: 12px;
        margin: 20px auto;
        width: 90%;
    }

    .terms-container h1 {
        font-size: 28px;
        margin-top: 70px;
    }

    .terms-container h2 {
        font-size: 18px;
        margin-top: 8px;
        margin-bottom: 6px;
    }

    .terms-container h3 {
        font-size: 16px;
    }

    .terms-container p, 
    .terms-container ul {
        font-size: 13px;
    }

    .terms-container ul {
        margin-bottom: 25px;
    }

    .terms-container .last-updated {
        font-size: 12px;
        margin-top: 35px;
    }
}
