.faq {
  padding: 2rem;
  background-color: #f9f9f9;
  font-family: 'Poppins', sans-serif;
  max-width: 1200px;
  margin: auto;
}

.faq-item {
  margin-bottom: 20px;
}
.faq-question {
  font-size: 1.2rem;
  font-weight: bold;
}
.faq-answer {
  font-size: 1rem;
  color: #333;
}
.faq-answer a {
  color: blue;
  text-decoration: underline;
}

.faq-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: #2D3142;
  margin-bottom: 1.5rem;
}

.faq-title .highlight {
  color: #0a7273;
}

.faq-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.faq-item {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.faq-item.open {
  background-color: #f4f4f4;
  border-left: 4px solid #FDA521;
}

.faq-question {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.arrow {
  font-size: 1.5rem;
  color: #0a7273;
  transition: transform 0.3s ease-in-out;
}

.faq-answer {
  font-size: 1rem;
  color: #555;
  margin-top: 1rem;
  padding-left: 20px;
  line-height: 1.5;
}

/* Base styles for larger screens (already defined) */

/* Responsive styles for tablets (768px and below) */
@media screen and (max-width: 768px) {
  .faq {
    padding: 1.5rem;
  }

  .faq-title {
    font-size: 2rem; /* Reduce title font size */
  }

  .faq-question {
    font-size: 1.15rem; /* Slightly smaller questions */
  }

  .faq-answer {
    font-size: 0.95rem; /* Slightly smaller answers */
    padding-left: 15px; /* Adjust padding for smaller screens */
  }

  .faq-item {
    padding: 1rem; /* Reduce padding for FAQ items */
  }
}

/* Responsive styles for smartphones (360px and below) */
@media screen and (max-width: 360px), (max-width: 412px){
  .faq {
    padding: 1.25rem; /* Smaller overall padding */
  }

  .faq-title {
    font-size: 1.75rem; /* Further reduce title font size */
    margin-bottom: 1rem;
  }

  .faq-question {
    font-size: 1rem; /* Adjust question font size */
  }

  .faq-answer {
    font-size: 0.85rem; /* Adjust answer font size */
    padding-left: 10px; /* Reduce left padding for answers */
  }

  .faq-item {
    padding: 0.75rem; /* Reduce padding for FAQ items */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Smaller shadow */
  }

  .arrow {
    font-size: 1.25rem; /* Reduce arrow size */
  }
}
@media screen and (max-width: 480px) {
  .faq {
    padding: 1.5rem;
  }

  .faq-title {
    font-size: 1.85rem; /* Slightly smaller for better mobile display */
    margin-bottom: 1.25rem;
  }

  .faq-question {
    font-size: 1.1rem; /* Adjusted for readability */
  }

  .faq-answer {
    font-size: 0.9rem; /* Slightly smaller answer text */
    padding-left: 12px; /* Adjusted padding */
  }

  .faq-item {
    padding: 1rem; /* Optimized padding for touch interaction */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .arrow {
    font-size: 1.35rem; /* Adjusted arrow size */
  }
}
