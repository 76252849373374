/* Container Styles for Google and Meta Ads */
.google-facebook-ads-container {
    width: 80%;
    margin: 70px auto 0 auto; /* 70px margin from top */
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  /* Heading Styles */
  .google-facebook-ads-container h1 {
    text-align: center;
    margin-top: 40px;
    font-size: 35px;
    font-weight: bold;
    color: #2980b9;
  }
  
  .google-facebook-ads-container h2 {
    color: #2980b9;
  margin-top: 0px;
  font-size: 20px;
  margin-bottom: 15px;
  text-align: left;
  }
  
  /* Paragraph and List Styles */
  .google-facebook-ads-container p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 15px;
    color: black ;
    text-align: left ;
  }
  
  .google-facebook-ads-container ul {
    margin-left: 20px;
    font-size: 14px;
  }
  
  /* Image Styling */
  .google-facebook-ads-container img {
    max-width: 70%; /* Reduce image width */
    height: auto;
    display: block;
    margin: 15px auto; /* Center the images and provide spacing */
    border-radius: 8px;
  }
  
  /* List Item Styles */
  .google-facebook-ads-container ul li {
    margin-bottom: 8px;
  }
  
  /* Icon Styles */
  .google-facebook-ads-container h3 > span {
    font-size: 20px;
    margin-right: 8px;
  }
  
  /* Contact Section */
  .google-facebook-ads-container h2:last-of-type {
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
  }
  
  .google-facebook-ads-container .cta p {
    font-size: 16px;
    text-align: center ;
  }

  .google-facebook-ads-container .cta h2:last-of-type {
    text-align: center ;
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
  } 
  .google-facebook-ads-container .cta p:last-of-type {
    text-align: center ;
    font-size: 16px;
    color: #2980b9;
  }
  
  .cta {
    text-align: center; /* Centers the text and the button */
  }
  
  .quotation-button {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 20px;
    background-color: #FDA521; 
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .quotation-button:hover {
    background-color: #00796b;
    transform: scale(1.05);
  }
  
  /* Mobile Responsiveness for max-width 360px */
  @media (max-width: 360px), (max-width: 412px) {
    .google-facebook-ads-container {
      padding: 8px;
      margin: 70px auto 0 auto; /* 70px margin from top */
      width: 90%; /* Increase width on smaller screens */
    }
  
    .google-facebook-ads-container h1 {
      font-size: 30px;
    }
  
    .google-facebook-ads-container h2 {
      font-size: 20px !important;
    }
  
    .google-facebook-ads-container p, .google-facebook-ads-container ul {
      font-size: 14px;
    }
  
    .google-facebook-ads-container img {
      max-width: 110% !important;
      max-width: 60%; /* Further reduce image width on mobile */
    }
  
    .google-facebook-ads-container ul {
      margin-left: 0px;
    }
  
    .google-facebook-ads-container button, .google-facebook-ads-container a {
      font-size: 14px;
      padding: 6px 12px;
    }
  }
  
  
  /* Mobile Responsiveness for max-width 768px */
  @media (max-width: 768px) {
    .google-facebook-ads-container {
      padding: 10px;
      width: 80%; /* Maintain 80% width on medium screens */
    }
  
    .google-facebook-ads-container h1 {
      font-size: 26px;
    }
  
    .google-facebook-ads-container h2 {
      font-size: 20px;
    }
  
    .google-facebook-ads-container h3 {
      font-size: 16px;
    }
  
    .google-facebook-ads-container p, .google-facebook-ads-container ul {
      font-size: 15px;
    }
  
    .google-facebook-ads-container img {
      max-width: 110% !important;
      max-width: 75%; /* Maintain moderate image size */
    }
  
    .google-facebook-ads-container ul {
      margin-left: 0px ;
    }
  
    .google-facebook-ads-container button, .google-facebook-ads-container a {
      font-size: 16px;
      padding: 8px 16px;
    }
  }
  @media (max-width: 480px) {
    .google-facebook-ads-container {
        padding: 10px;
        width: 85%; /* Slightly reduce width for better fit */
    }

    .google-facebook-ads-container h1 {
        font-size: 28px;
    }

    .google-facebook-ads-container h2 {
        font-size: 18px;
    }

    .google-facebook-ads-container h3 {
        font-size: 16px;
    }

    .google-facebook-ads-container p,
    .google-facebook-ads-container ul {
        font-size: 14px;
    }

    .google-facebook-ads-container img {
        max-width: 80%; /* Keep images manageable */
    }

    .google-facebook-ads-container ul {
        margin-left: 10px; /* Keep proper spacing */
    }

    .google-facebook-ads-container button,
    .google-facebook-ads-container a {
        font-size: 14px;
        padding: 10px 14px;
    }
}
