.portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:0 2rem;
  font-family: Arial, sans-serif;
 
}

.portfolio-title {
  text-align: center;
  margin-bottom: 2rem;
}

.portfolio-title h2 {
  margin-left: 0.7rem;
  margin-bottom: 0;
  font-size: 3rem;
  color: #0a7273;
  font-weight: bold;
}

.portfolio-title p {
  font-size: 2rem;
  color: #333;
  line-height: 1.2;
  margin-top: 0.25rem;
}

.portfolio-logos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  width: 100%;
  max-width: 1200px;
}
.portfolio-logos a{
  text-decoration: none;
}

.logo-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s;
}

.logo-card:hover {
  transform: translateY(-5px);
}

.logo-card img {
  max-width: 150px;
  height: 100px;
  margin-bottom: 1rem;
}

.logo-card p {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

.dark-card {
  color: #FDA521;
}

.light-card {
  color: #333;
}

.quotation-now-button {
  margin-top: 2rem;
  display: inline-block;
  padding: 12px 20px;
  background: linear-gradient(45deg, #ff6b00, #ff9800); /* Vibrant Gradient */
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 50px; /* Rounded shape for a premium look */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
  box-shadow: 0px 4px 10px rgba(255, 105, 0, 0.3);
  position: relative;
  overflow: hidden;
}

.quotation-now-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.6s ease-in-out;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.quotation-now-button:hover::after {
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
}

.quotation-now-button:hover {
  background: linear-gradient(45deg, #ff9800, #ff6b00);
  transform: scale(1.08);
  box-shadow: 0px 6px 15px rgba(255, 105, 0, 0.5);
}


@media (max-width: 768px) {
  .portfolio-logos {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .portfolio-title h2 {
    font-size: 2.5rem;
  }

  .portfolio-title p {
    font-size: 1.5rem;
  }

  .logo-card p {
    font-size: 0.9rem;
  }
}

/* @media (max-width: 480px) {
  .portfolio-logos {
    grid-template-columns: 1fr;
  }

  .portfolio-title h2 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .portfolio-title p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .logo-card p {
    font-size: 0.9rem;
    text-align: center;
  }

  .logo-card img {
    max-width: 100px;
    margin-bottom: 0.5rem;
  }

  .logo-card {
    padding: 0.8rem;
  }
} */

@media (max-width: 360px), (max-width: 412px)  {
  .portfolio-container {
    margin-top: 0;
    padding: 01rem;
  }

  .portfolio-title h2 {
    margin-top: 0;
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .portfolio-title p {
    font-size: 1.5rem;
    margin-top: 0;
  }

  .portfolio-logos {
    gap: 0.5rem;
  }

  .logo-card {
    padding: 0.5rem;
  }

  .logo-card img {
    max-width: 90px;
    height: 60px;
    margin-bottom: 0.3rem;
  }

  .logo-card p {
    font-size: 0.8rem;
  }
}
@media (max-width: 480px) {
  .portfolio-logos {
    grid-template-columns: 1fr; /* Single column for better mobile view */
    gap: 0.8rem;
  }

  .portfolio-title h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .portfolio-title p {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .logo-card {
    padding: 0.7rem;
  }

  .logo-card img {
    max-width: 100px;
    height: 70px;
    margin-bottom: 0.5rem;
  }

  .logo-card p {
    font-size: 0.9rem;
    text-align: center;
  }

  .quotation-now-button {
    font-size: 16px;
    padding: 10px 18px;
  }
}
