
.home-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 0;
  text-align: left;
  
}

.text-content {
  flex: 1;
  padding-right: 20px;
  text-align: left;
}

.text-content h1 {
  font-size: 38px;
  font-weight: 700;
  line-height: 1.3;
  color: #0A7273;
  margin: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  font-family: 'Poppins', sans-serif;
}

.text-content p {
  font-size: 18px;
  margin-top: 20px;
  color: #0a7273;
  line-height: 1.5;
  font-weight: 500;
}

.and{
  color: black;
}

.h2{
  color: black;
  font-size: 33.5px;
}

.sales-funnel-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  padding: 2rem;
}

.sales-funnel-content {
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
}

.funnel-image {
  flex: 1;
  display: flex;
  /* max-width: 45%; */
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem !important;
}

.funnel-image h2 {
  margin-top: 0px;
  margin-bottom: 15px !important;
  font-size: 2.5rem;
  margin-bottom: 0;
  color: #333;
}

.funnel-image img {
  width: 70%;
  height: 650px;
}

.sales-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  font-size: 1.2rem;
  text-align: left;
  overflow-x: auto;
  display: block;
}

.sales-table th,
.sales-table td {
  border: 1px solid #ddd;
  padding: 15px;
}

.sales-table th {
  background-color: #162F4C;
  color: #fff;
  font-weight: bold;
}

.sales-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.sales-table tr:hover {
  background-color: #f1f1f1;
}


.image-content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0;
}

.image-content img {
  width: 500px;
  height: 500px;
}

.explore-button {
  margin-top: 30px;
  padding: 15px 30px;
  background: linear-gradient(45deg, #fda521, #ff7600); /* Gradient for modern look */
  color: white;
  border: none;
  font-weight: bold;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 15px rgba(253, 165, 33, 0.4);
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

/* ✨ Add Ripple Effect */
.explore-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.6s ease-in-out;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.explore-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
}

/* 🌟 Interactive Effects */
.explore-button:hover {
  background: linear-gradient(45deg, #0a7273, #0f9b94);
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(10, 114, 115, 0.5);
}

/* ✨ Pulsating Animation to Attract Attention */
@keyframes pulse {
  0% {
    box-shadow: 0 0 15px rgba(253, 165, 33, 0.4);
  }
  50% {
    box-shadow: 0 0 25px rgba(253, 165, 33, 0.6);
  }
  100% {
    box-shadow: 0 0 15px rgba(253, 165, 33, 0.4);
  }
}

.explore-button {
  animation: pulse 1.5s infinite alternate;
}


.footer-icons {
  padding: 30px 0;
  background-color: #ffdd8e;
  margin-top: 30px;
  position: relative;
  white-space: nowrap;
  overflow: hidden; /* Ensures items outside the container are hidden */
}

.icons-slide{
  display: inline-block;
  width: 100%;
  animation: slide 10s linear infinite;
}

/* Animation to scroll images */
@keyframes slide {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.footer-icons img {
  width: 50px;
  height: auto;
  margin: 0 90px;
  transition: transform 0.3s ease;
}

.footer-icons img:hover {
  transform: scale(1.1);
}

@media (min-width: 1024px){
  .funnel-image, .funnel-table {
    width:50%;
  }
}

@media (max-width: 768px) {
  .home-section {
    margin-top: 50px;
  }

  .home-content {
    flex-direction: column;
    padding: 0 ;
  }

  .text-content {
    padding-right: 0;
    text-align: center;
    flex: 0 0 100%;
  }

  .text-content h1 {
    margin-top: 20px !important;
    font-size: 30px;
  }

  .h2 {
    font-size: 28px;
  }

  .text-content p {
    font-size: 20px;
  }

  .image-content {
    /* flex: 0 0 100%; */
    margin-top: 20px;
  }

  .footer-icons {
    padding: 15px 0;
  }

  .footer-icons img {
    width: 40px; /* Reduce icon size for better fit */
    margin: 0 35px; /* Reduce spacing between icons */
  }

  .sales-funnel-section {
    margin-top: 0;
    padding: 10px;
  }

  .sales-funnel-content {
    align-items: center;
    flex-direction: column;
  }

 .funnel-table {
    max-width: 90% !important;
    margin-top: 0;
  }

  .funnel-image img {
    width: 500px ;
    height: 500px;
  }

  .funnel-image h2 {
    font-size: 3rem ;
    margin-left: 5px;
    margin-bottom: 20px ;
    align-items: center ;
    width: 100%;
    height: auto;
  }

  .sales-table {
    font-size: 1rem;
  }
}

@media (max-width: 360px) {
  .home-section, .home-content {
    padding: 0;
    margin-top: 40px !important;
    width: 100%;
  }

  .text-content h1 {
    margin-top: 50px;
    font-size: 20px;
  }

  .h2 {
    font-size: 18px;
  }

  .text-content p {
    font-size: 12px;
  }

  .explore-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .footer-icons {
    padding: 15px 0;
  }

  .footer-icons img {
    width: 25px; /* Reduce icon size for better fit */
    margin: 0 12px !important; /* Reduce spacing between icons */
  }

  .image-content img {
    width: 300px;
    height: 300px;
  }

  .funnel-table {
    max-width: 110% !important;
  }

  .funnel-image img {
    width: 250px ;
    height: 250px;
  }

  .sales-table {
    font-size: 0.6rem;
  }

}
@media (max-width: 412px) {
  .home-section, .home-content {
    padding: 0;
    margin-top: 70px;
    width: 100%;
  }

  .text-content h1 {
    margin-top: 50px;
    font-size: 20px;
  }

  .h2 {
    font-size: 18px;
  }

  .text-content p {
    font-size: 12px;
  }

  .explore-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .footer-icons {
    padding: 15px 0;
    margin-bottom: 20px;
  }

  .footer-icons img {
    width: 25px; /* Reduce icon size for better fit */
    margin: 0 16px; /* Reduce spacing between icons */
  }

  .image-content img {
    width: 300px;
    height: 300px;
  }

  .funnel-table {
    max-width: 110% !important;
  }

  .funnel-image img {
    width: 250px ;
    height: 250px;
  }

  .sales-table {
    font-size: 0.6rem;
  }

}
@media (max-width: 480px) {
  .home-section, .home-content {
    padding: 0;
    margin-top: 70px;
    width: 100%;
  }

  .text-content h1 {
    margin-top: 50px;
    font-size: 20px;
  }

  .h2 {
    font-size: 18px;
  }

  .text-content p {
    font-size: 12px;
  }

  .explore-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .footer-icons {
    padding: 15px 0;
    margin-bottom: 20px;
  }

  .footer-icons img {
    width: 25px; /* Reduce icon size for better fit */
    margin: 0 16px; /* Reduce spacing between icons */
  }

  .image-content img {
    width: 300px;
    height: 300px;
  }

  .funnel-table {
    max-width: 110% !important;
  }

  .funnel-image img {
    width: 250px ;
    height: 250px;
  }

  .sales-table {
    font-size: 0.6rem;
  }
  
}
