.saptam-services-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 40px 20px;
    gap: 20px;
}

.saptam-service-card {
    flex: 1;
    min-width: 300px;
    padding: 40px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.saptam-service-card:nth-child(odd) {
    background: #ffcc00; /* Yellow */
}

.saptam-service-card:nth-child(even) {
    background: #f9f9f9; /* White */
}

.saptam-service-icon {
    font-size: 50px;
    margin-bottom: 20px;
}

.saptam-service-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #000;
}

.saptam-service-description {
    font-size: 16px;
    color: #333;
    line-height: 1.6;
}

/* Media Queries for Responsiveness */

/* For 768px */
@media screen and (max-width: 768px) {
    .saptam-services-container {
        padding: 30px 15px;
        gap: 15px;
    }

    .saptam-service-card {
        min-width: 220px; /* Adjust the card size */
        padding: 30px; /* Reduce padding */
    }

    .saptam-service-title {
        font-size: 20px; /* Smaller title size */
    }

    .saptam-service-description {
        font-size: 14px; /* Smaller text */
    }
}

/* For 480px */
@media screen and (max-width: 480px) {
    .saptam-services-container {
        padding: 20px 10px;
        gap: 10px;
    }

    .saptam-service-card {
        min-width: 200px; /* Adjust the card size */
        padding: 20px; /* Reduce padding */
    }

    .saptam-service-title {
        font-size: 18px; /* Smaller title size */
    }

    .saptam-service-description {
        font-size: 12px; /* Smaller text */
    }
}

/* For 412px */
@media screen and (max-width: 412px) {
    .saptam-services-container {
        padding: 15px 5px;
        gap: 10px;
    }

    .saptam-service-card {
        min-width: 180px; /* Adjust the card size */
        padding: 15px; /* Reduce padding */
    }

    .saptam-service-title {
        font-size: 16px; /* Smaller title size */
    }

    .saptam-service-description {
        font-size: 12px; /* Smaller text */
    }
}
