html {
  scroll-behavior: smooth;
}

.privacy-policy-container {
  width: 80%;
  margin: 70px auto 0 auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
}

.privacy-policy-container h1 {
  text-align: center;
  margin-top: 40px;
  font-size: 35px;
  font-weight: bold;
  color: #2980b9;
}

.privacy-policy-container h2 {
  color: #2980b9;
  margin-top: 30px;
  font-size: 20px;
  text-align: left ;
  margin-bottom: 10px;
}

.privacy-policy-container p {
  font-size: 14px;
  line-height: 1.6;
  color: #333333;
  margin-bottom: 10px;
}

.privacy-policy-container a {
  color: #3498db;
  text-decoration: underline;
}

.privacy-policy-container a:hover {
  text-decoration: underline;
  color: #2980b9;
}

@media (max-width: 768px) {
  .privacy-policy-container {
    margin-top: 100px;
    padding: 20px;
    width: 80%;
  }

  .privacy-policy-container h1 {
    font-size: 35px;
    margin-top: 20px;
  }

  .privacy-policy-container h2 {
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .privacy-policy-container p {
    font-size: 20px;
    margin-bottom: 12px;
  }
}

@media (max-width: 360px), (max-width: 412px) {
  .privacy-policy-container {
    margin: 15px auto;
    padding: 10px;
  }

  .privacy-policy-container h1 {
    font-size: 30px;
    margin-top: 60px;
  }

  .privacy-policy-container h2 {
    font-size: 20px !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .privacy-policy-container p {
    font-size: 12px ;
    margin-bottom: 8px;
  }
}
@media (max-width: 480px) {
  .privacy-policy-container {
    width: 90%;
    margin: 50px auto;
    padding: 15px;
  }

  .privacy-policy-container h1 {
    font-size: 32px;
    margin-top: 30px;
  }

  .privacy-policy-container h2 {
    font-size: 22px;
    margin-top: 8px;
    margin-bottom: 6px;
  }

  .privacy-policy-container p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
