/* Container Styles for E-Commerce Development */
.ecommerce-development-container {
    width: 80%;
    margin: 70px auto 0 auto; /* 70px margin from top */
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Heading Styles */
.ecommerce-development-container h1 {
    text-align: center;
  margin-top: 40px;
  font-size: 35px;
  font-weight: bold;
  color: #2980b9;
}

.ecommerce-development-container h2 {
    color: #2980b9;
    margin-top: 0px;
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
}

/* Paragraph and List Styles */
.ecommerce-development-container p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 15px;
    color: black;
    text-align: left;
}

.ecommerce-development-container ul {
    margin-left: 20px;
    font-size: 14px;
}

/* Image Styling */
.ecommerce-development-container img {
    max-width: 70%; /* Reduce image width */
    height: auto;
    display: block;
    margin: 15px auto; /* Center the images and provide spacing */
    border-radius: 8px;
}

/* List Item Styles */
.ecommerce-development-container ul li {
    margin-bottom: 8px;
}

/* Icon Styles */
.ecommerce-development-container h3 > span {
    font-size: 20px;
    margin-right: 8px;
}

/* Contact Section */
.ecommerce-development-container h2:last-of-type {
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
}

.ecommerce-development-container .cta p {
    font-size: 16px;
    text-align: center;
}

.ecommerce-development-container .cta h2:last-of-type {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 40px;
}

.ecommerce-development-container .cta p:last-of-type {
    text-align: center;
    font-size: 16px;
    color: #2980b9;
}

.cta {
    text-align: center; /* Centers the text and the button */
}

.quotation-button {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 20px;
    background-color: #FDA521; 
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.quotation-button:hover {
    background-color: #00796b;
    transform: scale(1.05);
}

/* Mobile Responsiveness for max-width 360px */
@media (max-width: 360px), (max-width: 412px) {
    .ecommerce-development-container {
        padding: 8px;
        margin: 70px auto 0 auto; /* 70px margin from top */
        width: 90%; /* Increase width on smaller screens */
    }

    .ecommerce-development-container h1 {
        font-size: 30px;
    }

    .ecommerce-development-container h2 {
        font-size: 20px !important;
    }

    .ecommerce-development-container p, .ecommerce-development-container ul {
        font-size: 14px;
    }

    .ecommerce-development-container img {
        max-width: 110% !important;
        max-width: 60%; /* Further reduce image width on mobile */
    }

    .ecommerce-development-container ul {
        margin-left: 0px;
    }

    .ecommerce-development-container button, .ecommerce-development-container a {
        font-size: 14px;
        padding: 6px 12px;
    }
}

/* Mobile Responsiveness for max-width 768px */
@media (max-width: 768px) {
    .ecommerce-development-container {
        padding: 10px;
        width: 80%; /* Maintain 80% width on medium screens */
    }

    .ecommerce-development-container h1 {
        font-size: 26px;
    }

    .ecommerce-development-container h2 {
        font-size: 20px;
    }

    .ecommerce-development-container h3 {
        font-size: 16px;
    }

    .ecommerce-development-container p, .ecommerce-development-container ul {
        font-size: 15px;
    }

    .ecommerce-development-container img {
        max-width: 110% !important;
        max-width: 75%; /* Maintain moderate image size */
    }

    .ecommerce-development-container ul {
        margin-left: 0px;
    }

    .ecommerce-development-container button, .ecommerce-development-container a {
        font-size: 16px;
        padding: 8px 16px;
    }
}
/* Mobile Responsiveness for max-width 480px */
@media (max-width: 480px) {
    .ecommerce-development-container {
        padding: 12px;
        width: 85%; /* Adjusted width for better layout */
    }

    .ecommerce-development-container h1 {
        font-size: 28px;
        margin-top: 30px;
    }

    .ecommerce-development-container h2 {
        font-size: 18px;
    }

    .ecommerce-development-container p, 
    .ecommerce-development-container ul {
        font-size: 14px;
    }

    .ecommerce-development-container img {
        max-width: 70%; /* Keeps images properly scaled */
    }

    .ecommerce-development-container .cta p {
        font-size: 15px;
    }

    .quotation-button {
        font-size: 15px;
        padding: 10px 18px;
    }
}
