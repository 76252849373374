.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  background-color: #162F4C;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  gap: 0.5rem;
  flex-wrap: nowrap;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 2rem;
  flex-wrap: nowrap;
}

.footer-section {
  flex: 1;
  margin: 0 1rem;
  /* min-width: 250px; */
}

.footer-section h3,
.footer-section h4 {
  color: #F9A826;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.footer-section p {
  color: #B3B3B3;
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 0.8rem;
}

.footer-section a {
  color: #B3B3B3;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.2s ease;
  font-weight: 600;
  font-size: 0.9rem;
}

.footer-section a:hover {
  color: #F9A826;
}

.footer-section p svg {
  margin-right: 8px;
  color: #F9A826;
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.2s ease;
}

.contact-message {
  margin-top: 15px;
  text-align: left;
  margin-left: 50px;
}

.contact-message p {
  text-align: left;
}

.footer-section.our-services ul {
  padding: 0;
}

.footer-section.our-services li {
  margin-left: 60px !important;
  margin: 20px 0;
}

.footer-section.our-services h4 {
  margin-left: 55px;
  margin-top: 35px;
}

.footer-section.quick-links h4 {
  margin-left: 40px;
}

.footer-section.quick-links li {
  margin-left: 20px !important;
  margin: 20px 0;
}

.footer-bottom {
  width: 100%;
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.footer-links {
  display: flex;
  gap: 2rem;
}

.footer-links a {
  color: #B3B3B3;
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.3s ease;
  text-align: center;
}

.footer-links a:hover {
  color: #F9A826;
}

.footer-copyright {
  color: #B3B3B3;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.footer-copyright p {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin: 0;
}

.footer-section .social-icons {
  display: flex;
  justify-content: left;
  gap: 20px;
  margin-top: 10px;
}

.social-icons a {
  display: inline-block;
}

.social-icons img, .social-icons svg {
  width: 24px;
  height: 24px;
}

.social-link {
  text-decoration: none;
}

.social-link:hover img, .social-link:hover svg {
  opacity: 0.8;
}

.organization-details p,
.organization-details a {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #B3B3B3;
  font-size: 1rem;
  margin-bottom: 0.8rem;
  text-decoration: none;
}

.organization-details svg {
  flex-shrink: 0;
  font-size: 1.2rem;
  color: #F9A826;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .footer-section {
    margin: 0;
    min-width: 100%;
    text-align: center;
  }

  .footer-links {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .footer-copyright {
    justify-content: center;
    text-align: center;
  }

  .footer-section.our-services h4{
    margin-left: 0;
  }

  .footer-section.quick-links h4{
    margin-left: 0;
  }

  .footer-section.our-services ul{
    margin-left: 235px;
    text-align: left;
  }

  .footer-section.quick-links ul{
    margin-left: 285px;
    text-align: left;
  }

  .footer-section .social-icons {
    display: flex;
    margin-left: 0px;
    justify-content: center !important;
    gap: 20px;
    margin-top: 30px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .footer-section.contact-message p{
    text-align: center;
  }

  .organization-details p {
    margin-left: 245px;
    text-align: left ;
    font-size: 1rem;
    gap: 10px;
  }

  .organization-details svg {
    font-size: 1.9rem ;
  }
}

@media (max-width: 360px),(max-width: 412px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .footer-section.contact-message p{
    text-align: center;
  }

  .footer-section {
    margin: 0;
    min-width: 100%;
    text-align: center;
  }

  .footer-links {
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
  }

  .footer-copyright {
    justify-content: center;
    text-align: center;
  }

  .footer-section.our-services ul{
    margin-left: 30px;
    text-align: left;
  }

  .footer-section.quick-links ul{
    margin-left: 85px;
    text-align: left;
  }

  .footer-section .social-icons {
    display: flex;
    justify-content: center ;
    gap: 20px;
    margin-top: 30px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    gap: 0.6rem;
  }

  .organization-details p {
    margin-left: 40px ;
    text-align: left ;
    font-size: 0.9rem;
    gap: 6px;
  }

  .organization-details svg {
    font-size: 1rem ;
  }
}
@media (max-width: 480px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .footer-section {
    margin: 0;
    min-width: 100%;
    text-align: center;
  }

  .footer-section.contact-message p {
    text-align: center;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .footer-copyright {
    justify-content: center;
    text-align: center;
  }

  .footer-section.our-services h4,
  .footer-section.quick-links h4 {
    margin-left: 0;
  }

  .footer-section.our-services ul {
    margin-left: 50px !important;
    text-align: left;
  }

  .footer-section.quick-links ul {
    margin-left: 70px !important;
    text-align: left;
  }

  .footer-section .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .organization-details p {
    margin-left: 50px;
    text-align: left;
    font-size: 0.95rem;
    gap: 8px;
  }

  .organization-details svg {
    font-size: 1.3rem;
  }
}
@media (max-width: 480px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }

  .footer-section {
    margin: 0;
    min-width: 100%;
    text-align: center;
  }

  .footer-section.contact-message p {
    text-align: center;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .footer-copyright {
    justify-content: center;
    text-align: center;
  }

  .footer-section.our-services h4,
  .footer-section.quick-links h4 {
    margin-left: 0;
  }

  .footer-section.our-services ul {
    margin-left: 100px;
    text-align: left;
  }

  .footer-section.quick-links ul {
    margin-left: 120px;
    text-align: left;
  }

  .footer-section .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .organization-details p {
    margin-left: 50px;
    text-align: left;
    font-size: 0.95rem;
    gap: 8px;
  }

  .organization-details svg {
    font-size: 1.3rem;
  }
}
