.contact-us-container {
  cursor: pointer;
  margin-top: 60px !important;
  padding: 40px;
  border-radius: 10px;
  max-width: 900px;
  margin: auto;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2rem;
  color: #2D3142;
  text-align: center;
  margin-bottom: 30px;
}

span {
  color: #0a7273;
}

.contact-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.form-left {
  padding-left: 20px;
  width: 45%;
}

.bordered {
  padding-bottom: 10px; /* Add some space between the content and the line */
  border-bottom: 2px solid #d5d5d5; /* Light gray line */
}


.form-right {
  width: 45%;
  padding-right: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  font-size: 1rem;
  color: #2D3142;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea,
.form-group select {
  cursor: pointer;
  width: 95%;
  padding: 12px;
  border: 2px solid #FDA521;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fff;
}

.form-group select {
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="%230a7273" d="M7 10l5 5 5-5H7z"/></svg>');
  border: 2px solid #FDA521;
}

.form-group select:focus,
.form-group select:active {
  outline: none;
  border: 2px solid #FDA521;
}

textarea {
  resize: none;
  height: 103px;
}

.submit-container {
  width: 100%;
  text-align: left;
}

.submit-btn {
  background-color: #FDA521;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 12px 0;
  border: none;
  border-radius: 10px;
  width: 30%;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #0a7273;
}

.contact-email {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
}

.form-group a {
  color: #0a7273;
  text-decoration: none ;
}

.form-group a:hover {
  text-decoration: underline;
}

.icon-text {
  display: flex; 
  align-items: center; 
  gap: 8px; 
}

.icon {
  font-size: 1.2rem; 
}

.form-group-left{
  h3{
    color: #0a7273;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content {
  background: azure;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: blowIn 0.5s ease-in-out; /* Blow effect animation */
  transform-origin: center;
}

@keyframes blowIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-content h2 {
  margin: 0 0 10px;
}

.modal-content p {
  margin: 0 0 20px;
}

.ok-btn {
  background-color: #0a7273;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.ok-btn:hover {
  background-color: #FDA521;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}


@media (max-width: 768px) {
  .contact-us-container {
    padding: 20px;
  }

  h2 {
    font-size: 2rem;
    text-align: center;
  }

  .contact-form {
    flex-direction: column;
    gap: 10px;
  }

  .form-left, .form-right {
    width: 100%;
    padding: 0;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    padding: 7px;
    font-size: 0.9rem;
  }

  .submit-btn {
    width: 100%;
    padding: 12px;
  }
}

@media (max-width: 360px), (max-width: 412px) {
  .contact-us-container {
    padding: 15px;
  }

  h2 {
    font-size: 1.8rem !important;
    margin-bottom: 15px;
  }

  .contact-form {
    gap: 8px;
  }

  .form-group label {
    font-size: 0.9rem;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    padding: 5px;
    font-size: 0.85rem;
  }

  .submit-btn {
    font-size: 1rem;
    padding: 10px;
  }
}
/* Responsive Design for max-width 480px */
@media (max-width: 480px) {
  .contact-us-container {
    padding: 18px;
    margin-top: 30px;
  }

  h2 {
    font-size: 1.9rem;
    margin-bottom: 20px;
  }

  .contact-form {
    flex-direction: column;
    gap: 12px;
  }

  .form-left, .form-right {
    width: 100%;
    padding: 0;
  }

  .form-group label {
    font-size: 1rem;
  }

  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 90%;
    padding: 8px;
    font-size: 0.95rem;
  }

  .submit-btn {
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
  }

  .modal-content {
    width: 90%;
    padding: 15px;
  }

  .ok-btn {
    font-size: 1rem;
    padding: 10px 18px;
  }
}
