/* General Carousel Container Styling */
.container {
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Keeps dots and arrows below the carousel */
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
}

.container h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #2980b9;
}

/* Blog Card Row Styling */
.blog-card-row {
  display: flex;
  gap: 20px; /* Spacing between cards */
  overflow: hidden;
  width: 100%;
  justify-content: center;
  transition: transform 0.5s ease-in-out; /* Smooth scrolling */
  margin-bottom: 20px;
}

/* Individual Blog Card Styling */
.blog-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 300px;
  min-height: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* margin-bottom: 20px; */
}

.blog-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.blog-card img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.blog-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2980b9;
  margin-bottom: 10px;
}

.blog-card-preview {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.read-more-button {
  background-color: #fda521;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.read-more-button:hover {
  background-color: #0a7273;
  transform: scale(1.05);
}

/* Responsive Adjustments */

/* Tablet View */
@media (max-width: 768px) {
  .blog-card-row {
    flex-direction: column; /* Switch to a column layout */
    gap: 30px; /* Increase gap between stacked cards */
  }

  .blog-card {
    width: 80%; /* Slightly narrower for tablet devices */
    margin: 0 auto;
  }

}

/* Mobile View */
@media (max-width: 360px), (max-width: 412px) {
  .blog-card-row {
    flex-direction: column; /* Stacked cards */
    gap: 20px; /* Adjust spacing */
  }

  .blog-card-title{
    font-size: 22px !important;
  }

  .blog-card {
    width: 90%;
    margin: 0 auto;
  }
}
/* Mobile View for max-width 480px */
@media (max-width: 480px) {
  .blog-card-row {
    flex-direction: column; /* Stack cards vertically */
    gap: 25px; /* Adjust spacing */
  }

  .blog-card {
    width: 85%; /* Adjust card width for better fit */
    margin: 0 auto; /* Center the cards */
  }

  .blog-card-title {
    font-size: 1.3rem; /* Adjust heading size */
  }

  .blog-card-preview {
    font-size: 0.95rem; /* Adjust preview text size */
  }

  .read-more-button {
    padding: 8px 16px; /* Reduce button padding */
    font-size: 0.9rem;
  }
}
