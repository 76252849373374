.google-ads-container {
    font-family: Arial, sans-serif;
  color: #333;
    width: 100% !important;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;
    padding-top: 0;
    line-height: 2.5rem;
}

.google-ads-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    gap: 2rem;
}

.google-ads-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    object-fit: cover;
}

.google-ads-text {
    flex: 1;
}

.google-ads-text h2 {
    display: flex;
    align-items: flex-start;
    color: #0a7273;
    margin-bottom: 10px;
    width: 100%;
}

.google-ads-text p {
    line-height: 1.5;
    margin-bottom: 0;
}
/* ✅ Responsive Design for Mobile (412px) and Tablet (768px) */
@media (max-width: 768px) {
    .google-ads-content {
        flex-direction: column; /* Stack items on smaller screens */
        text-align: left;
        gap: 1.5rem;
    }

    .google-ads-image {
        max-width: 60%; /* Full width for better scaling */
    }

    .google-ads-text {
        width: 100%;
    }

    .google-ads-text h2 {
        font-size: 1.5rem;
        
    }

    .google-ads-text p {
        font-size: 1rem;
    }
}

@media (max-width: 412px) {
    .google-ads-container {
        padding: 2rem 0.5rem; /* Adjust padding for smaller screens */
    }

    .google-ads-image {
        max-width: 100%; /* Full width for better scaling */
    }

    .google-ads-text h2 {
        font-size: 1.3rem; /* Reduce title size */
    }

    .google-ads-text p {
        font-size: 0.95rem; /* Slightly smaller paragraph */
    }

    .google-ads-content {
        gap: 1rem; /* Reduce spacing */
    }
}
@media (max-width: 480px) {
    .google-ads-container {
        padding: 2rem 0.5rem; /* Slightly more padding than 412px */
    }

    .google-ads-image {
        max-width: 100%; /* Keeps the image large but scalable */
    }

    .google-ads-text {
        width: 100%;
    }

    .google-ads-text h2 {
        font-size: 2rem; /* Adjusted for mid-sized mobile screens */
    }

    .google-ads-text p {
        font-size: 1rem; /* Slightly larger than 412px for readability */
    }
}
